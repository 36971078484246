<template>
  <div class="index">
    <div class="content-box">
      <div class="search-box">
        <img
          class="search-img"
          src="@/assets/realTimeInfo/banner2.png"
          alt=""
        />
      </div>
      <div class="notice">
        <div class="title-box">
          <div class="title-name">信息资讯</div>
        </div>
        <div class="notice-list">
          <div class="list-box">
            <div class="notice-title-box">
              <div class="notice-title">通知公告</div>
              <div class="notice-more" @click="openList(1)">
                <span>更多</span>
                <img
                  class="notice-more-icon"
                  src="../../assets/home/ic_6.png"
                  alt=""
                />
              </div>
            </div>
            <div class="notice-content-list">
              <div
                class="n-l-item"
                v-for="(item, index) in NoticeList"
                :key="index"
                @click="openDetail(item.fk_news_id)"
              >
                <div class="n-l-item-title line-1">
                  {{ item.name }}
                </div>
                <div class="n-l-item-time">{{ item.date_creation }}</div>
              </div>
            </div>
          </div>
          <div class="list-box">
            <div class="notice-title-box">
              <div class="notice-title">会议发布</div>
              <div class="notice-more" @click="openList(2)">
                <span>更多</span>
                <img
                  class="notice-more-icon"
                  src="../../assets/home/ic_6.png"
                  alt=""
                />
              </div>
            </div>
            <div class="swipe">
              <el-carousel
                trigger="click"
                indicator-position="none"
                height="370px"
                @change="change"
              >
                <el-carousel-item
                  v-for="(item, index) in meetingList"
                  :key="index"
                >
                  <img
                    class="swipe-img"
                    :src="item.img"
                    alt=""
                    @click="openDetail(item.fk_news_id)"
                  />
                </el-carousel-item>
              </el-carousel>
              <div class="swipe-mask">
                <div class="mask-title line-1">
                  {{
                    meetingList[current] ? meetingList[current].name : "暂无"
                  }}
                </div>
                <div class="mask-dot">
                  <div
                    :class="['dot-item', current == index ? 'active' : '']"
                    v-for="(item, index) in meetingList.length"
                    :key="index"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="dynamic">
        <div class="title-box">
          <div class="title-name">工作动态</div>
          <div class="title-more" @click="openList(3)">
            <span>更多</span>
            <img class="more-icon" src="@/assets/home/ic_6.png" alt="" />
          </div>
        </div>
        <div class="dynamic-list">
          <div v-for="(item, index) in dynamicList" :key="index">
            <list-item :item="item" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import listItem from "./compontent/list-item";
import Api from "@/api";
export default {
  components: {
    listItem,
  },
  data() {
    return {
      current: 0,
      NoticeList: [],
      meetingList: [],
      dynamicList: [],
    };
  },
  mounted() {
    this.initPage();
    this.getTitle(3);
  },
  methods: {
    initPage() {
      Promise.all([
        Api.News({
          type: 1,
          keywords: "",
          page: 1,
          pageSize: 10,
        }),
        Api.News({
          type: 2,
          keywords: "",
          page: 1,
          pageSize: 9,
        }),
        Api.News({
          type: 3,
          keywords: "",
          page: 1,
          pageSize: 6,
        }),
      ]).then((res) => {
        this.NoticeList = res[0].data;
        this.meetingList = res[1].data;
        this.dynamicList = res[2].data;
      });
    },
    change(idx) {
      this.current = idx;
    },
    openDetail(id) {
      this.$router.push({
        name: "news_detail",
        query: {
          id,
        },
      });
    },
    openList(type) {
      this.$router.push({
        name: "news_all",
        query: {
          type,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-input__inner {
  height: 64px;
  border: none;
}
.index {
  width: 100%;
  background: url("../../assets/realTimeInfo/banner.png") no-repeat;
  background-position: center top;
  background-color: #f5f5f5;
  .content-box {
    width: 1200px;
    margin: 0 auto;
    .search-box {
      padding-top: 30px;
      width: 603px;
      display: flex;
      flex-direction: column;
      .search-img {
        width: 60%;
        height: auto;
      }
      .search {
        width: 100%;
        height: 64px;
        display: flex;
        align-items: center;
        padding-top: 40px;
        .input-box {
          flex: 1;
          height: 100%;
          border-radius: 10px 0 0 10px;
          overflow: hidden;
        }
        .search-btn {
          width: 125px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #0086ff;
          color: #fff;
          font-size: 22px;
          font-weight: 400;
          border-radius: 0px 10px 10px 0px;
          cursor: pointer;
        }
      }
    }
    .title-box {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 50px;
      padding-bottom: 26px;
      .title-name {
        font-size: 24px;
        font-weight: 400;
        color: #000000;
      }
      .title-more {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 400;
        color: #666666;
        cursor: pointer;
        .more-icon {
          width: 6px;
          height: 10px;
          margin-left: 6px;
        }
      }
    }
    .notice {
      width: 100%;
      padding-top: 40px;
      .swipe {
        padding-top: 17px;
        position: relative;
        .swipe-img {
          height: 100%;
          cursor: pointer;
        }
        .swipe-mask {
          position: absolute;
          bottom: 0;
          left: 0;
          z-index: 100;
          width: calc(100% - 10px);
          background-color: rgba(0, 0, 0, 0.3);
          height: 45px;
          display: flex;
          align-items: center;
          padding-left: 10px;
          .mask-title {
            width: 76%;
            font-size: 12px;
            font-weight: 400;
            color: #ffffff;
            margin-right: 10px;
          }
          .mask-dot {
            display: flex;
            align-items: center;
            .dot-item {
              width: 10px;
              height: 10px;
              background: #b4b4b4;
              border-radius: 50%;
              margin-right: 5px;
              &.active {
                background: #ffffff;
              }
            }
          }
        }
      }
      .notice-list {
        flex: 1;
        display: flex;
        justify-content: space-between;
        .list-box {
          width: calc(50% - 6px);
          background-color: #fff;
          border-radius: 10px;
          overflow: hidden;
          padding: 20px 18px 18px;
          box-sizing: border-box;
          z-index: 10;
          .notice-title-box {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 20px;
            border-bottom: 1px solid #ececec;
            .notice-title {
              font-size: 18px;
              font-weight: 400;
              color: #010101;
            }
            .notice-more {
              display: flex;
              align-items: center;
              font-size: 16px;
              font-weight: 400;
              color: #666666;
              cursor: pointer;
              .notice-more-icon {
                width: 6px;
                height: 10px;
                margin-left: 6px;
              }
            }
          }
          .notice-content-list {
            flex: 1;
            display: flex;
            flex-direction: column;
            padding-top: 10px;
            .n-l-item {
              flex: 1;
              display: flex;
              align-items: center;
              justify-content: space-between;
              cursor: pointer;
              line-height: 38px;
              &:hover .n-l-item-title {
                color: #1d2088;
              }
              &:hover .n-l-item-time {
                color: #1d2088;
              }
              .n-l-item-title {
                flex: 1;
                font-size: 14px;
                font-weight: 400;
                color: #666666;
              }
              .n-l-item-time {
                padding-left: 20px;
                font-size: 14px;
                font-weight: 400;
                color: #999999;
              }
            }
          }
        }
      }
    }
    .dynamic {
      width: 100%;
      padding-bottom: 70px;
      .dynamic-list {
        width: 100%;
        display: flex;
        flex-direction: column;
      }
    }
  }
}
</style>
