<template>
  <div class="item" @click="openDetail(item.fk_news_id)">
    <div class="item-img-box" v-if="item.img">
      <img class="item-img" :src="item.img || kong" alt="" />
    </div>
    <div class="item-right">
      <div class="right-name line-2">{{ item.name }}</div>
      <div class="right-title line-2">
        {{ item.remark }}
      </div>
      <div class="right-tip">
        <div class="tip-views">
          <span class="tip-title">阅读量</span>
          <span>{{ item.read_num }}</span>
        </div>
        <div class="tip-time">
          <span class="tip-title">发布于</span>
          <span>{{ item.date_creation }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    openDetail(id) {
      this.$router.push({
        name: "news_detail",
        query: {
          id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  box-sizing: border-box;
  position: relative;
  width: 100%;
  background: #ffffff;
  overflow: hidden;
  padding: 18px;
  border-radius: 10px;
  display: flex;
  margin-bottom: 12px;
  z-index: 10;
  cursor: pointer;
  .item-img-box {
    width: 240px;
    height: 160px;
    border-radius: 10px;
    overflow: hidden;
    margin-right: 18px;
    .item-img {
      height: 100%;
    }
  }
  .item-right {
    flex: 1;
    min-height: 130px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .right-name {
      font-size: 20px;
      font-weight: 400;
      color: #000000;
    }
    .right-title {
      font-size: 16px;
      font-weight: 400;
      line-height: 150%;
      color: #666666;
    }
    .right-tip {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      font-weight: 400;
      color: #999999;
      .tip-views {
        display: flex;
        align-items: center;
      }
      .tip-title {
        padding-right: 10px;
      }
    }
  }
}
</style>
